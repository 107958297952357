import './FooterStyle.css'

function Footer() {
 return (
  <div className="footer-box" >
   <span>©Alexkpot 2022.</span>
  </div>
 )
}

export default Footer;