const LinksData = [
  {
    imgPath: require('../../assets/images/benQMonitor.jpg'),
    productName: 'PD3220U｜32-inch 4K',
    description:
      'The PD3220U ships from the factory with default color settings that perfectly match Mac® and MacBookc® Pro laptop colors. Exclusive M-Book mode provides active color syncing, creating an additional layer of color precision assurance.',
    discount: '0',
    storeLink:
      'https://imsva91-ctp.trendmicro.com/wis/clicktime/v1/query?url=https%3a%2f%2fbenqurl.biz%2f3o5Jyn0&umid=CF219F23-FCCC-3105-B370-C911733F7BA0&auth=bd65de9ab001e9263876a02ec49928af70e9b967-0dabbd3d1be9a52e00b894653f9b7741b692afd6',
  },
  {
    imgPath: require('../../assets/images/camera-clamp.png'),
    productName: 'Camera Clamp',
    description:
      'YAYOYA Super Clamp with 360° Ballhead Articulating Friction Magic Arm, 22in/55cm Adjustable Magic Arm Camera Mount with Both 1/4" Thread Screw for LCD Monitor',
    discount: '0',
    storeLink: 'https://amzn.to/3HvLHxP',
  },
  {
    imgPath: require('../../assets/images/work_desktop.png'),
    productName: 'Work Setup',
    description:
      'Find all products that I use in my work setup. from my 38" ultrawide monitor, to my Airpods Pro.',
    discount: '0',
    storeLink: 'https://amzn.to/3Hh2QNy',
  },
  {
    imgPath: require('../../assets/images/personal_desktop.png'),
    productName: 'Personal Setup',
    description:
      'Personal means more than coding, what lenses I use, camera gear, wireless charger, wall rgb lights, etc.',
    discount: '0',
    storeLink: 'https://amzn.to/3h7Nv7i',
  },
  {
    imgPath: require('../../assets/images/gaming_setup.png'),
    productName: 'Gaming Setup',
    description:
      'I could not made my mind around what console to buy, so I got both of them. Take a look at that and other accesories as well.',
    discount: '0',
    storeLink:
      'https://drive.google.com/drive/folders/1WM7WSINZAp2UN4uP4I6nG6FHfP4PGdj9',
  },
  {
    imgPath: require('../../assets/images/grovemade.png'),
    productName: 'Grovemade',
    description:
      'Design Inspires, build your dream workspace, so you can get your best work done.',
    discount: '10% Off',
    storeLink: 'https://grovemade.com/?rfsn=4420281.fb68e2',
  },
  {
    imgPath: require('../../assets/images/desk.png'),
    productName: 'Autonomous desk',
    description:
      'USE code: 22ALEJANDRO for an special discount on any Autonomous product.',
    discount: '0',
    storeLink:
      'https://www.autonomous.ai/standing-desks/smartdesk-2-business?utm_source=pr&utm_medium=influencer&utm_campaign=growth&utm_content=Instagram&utm_term=22ALEJANDRO',
  },
  {
    imgPath: require('../../assets/images/wallpapers.jpg'),
    productName: 'Wallpapers',
    description:
      'A collection of curated wallpapers, some are made by me, others are form the internet. But all are free for you to use.',
    discount: '0',
    storeLink:
      'https://drive.google.com/drive/folders/1FOEIdGuVEXlrTaa-utWJ1UlSq_SQU3Qd?usp=share_link',
  },
  {
    imgPath: require('../../assets/images/vscode.png'),
    productName: 'VS Code Theme',
    description:
      'A bright, modern, contrasting dark theme for Visual Studio Code by @morgan.codes',
    discount: '0',
    storeLink:
      'https://marketplace.visualstudio.com/items?itemName=morgan-codes.morgan-codes-vscode-theme',
  },
]

export default LinksData
